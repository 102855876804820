.redeemreq-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 19px;
  gap: 15px;
  background: white;
  border-radius: 30px;
  width: 430px;
}

.redeemreq-item {
  height: 90px;
  width: 90px;
}
.redeemreq-img-wrapper {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  border-radius: 24px;

  display: flex;
  align-items: center;
  padding: 14px 14px;
}

.redeemreq-coin {
  height: 26px;
  margin-top: -5px;
}

.redeemreq-buttons {
  display: flex;
  flex-direction: column;
}

.redeemreq-buttons a {
  text-decoration: none;
  padding: 11px 34px;

  text-align: center;
  font-size: 12px;
  border-radius: 30px;
  color: white;
}

.redeemreq-redbtn {
  background: #d43b3b;
}

.redeemreq-bluebtn {
  background: #45b579;
}
