.submit-btnblue {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
}

.file-upload {
  margin-bottom: 110px;
}

.second-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  justify-content: space-between;
}

.seperator {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  flex-shrink: 0;
  justify-content: center;
}

.submit-form {
  text-align: center;
  margin-top: 30px;
}

/* .second-wrapper img {
  height: 150px;
  width: 150px;
  border-radius: 100px;
} */

.submit-btn {
  padding: 10px 60px;
  border-radius: 50px;
  border: none;
  font-size: 12px;
}

.submit-btnblue {
  padding: 10px 35px;
  color: white;
}
.form-rounded {
  border-radius: 12px;
}

.error-display {
  text-align: center;
  justify-content: center;
}

.bigwrapper {
  border-radius: 50px;
  background: white;
  background-image: url("../../images/curve.svg");
  background-size: 2000px 800px;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center -490px;
}

.curve {
  position: relative;
  height: 40px;
}

@media (max-width: 767px) {
  .seperator {
    flex-direction: column;
    gap: 0px;
  }
}
