.dish-btn-add {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  color: white;
}

.form-rounded-dish {
  border-radius: 12px;
  width: 400px;
}
