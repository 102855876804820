.list-food {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 0;
  gap: 2%;
}

.list-food a {
  text-decoration: none;
  color: black;
}

ul {
  list-style: none;
  text-align: center;
}

.list-food li a {
  display: flex;

  border-radius: 50px;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 150px;
  background: white;
}

.attendance-day-wrapper {
}

.attendance-day-wrapper .date-container {
  display: flex;

  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.attendance-display {
  background: white;

  border-radius: 30px;
  height: 80vh;
}

@media (max-width: 767px) {
  .attendance-day-wrapper .date-container {
    white-space: normal;
  }
}
