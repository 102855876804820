.office-flexcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
  text-align: center;
  gap: 25px;
}

.time {
  display: flex;
  flex-direction: row;
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: -20px;
}

.office-smwrapper {
  background: white;
  padding: 20px 10px;
  border-radius: 30px;
}

.two-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
}

.two-buttons a {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  padding: 3px 22px;
  border-radius: 40px;
  text-decoration: none;
  color: white;
  margin-top: -23px;
}

.office-wrapper {
  width: 300px;
}
