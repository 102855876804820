.list-dgc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0;
  gap: 2%;
}

.list-dgc a {
  text-decoration: none;
  color: black;
}

ul {
  list-style: none;
  text-align: center;
}

.list-dgc li a {
  display: flex;

  border-radius: 50px;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 140px;
  padding: 0px 10px;
  background: white;
}

.user-array-dgc {
  display: flex;
  flex-direction: row;
  width: 900px;
  flex-wrap: wrap;
  gap: 20px;
}
