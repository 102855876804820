.list-food {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 0;
  gap: 2%;
}

.list-food a {
  text-decoration: none;
  color: black;
}

ul {
  list-style: none;
  text-align: center;
}

.list-food li a {
  display: flex;

  border-radius: 50px;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 150px;
  background: white;
}

/* .list-food li a:hover {
  color: white;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
} */

.user-array-food {
  display: flex;
  flex-direction: row;
  width: 900px;
  flex-wrap: wrap;
  gap: 20px;
}

.form-format {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.form-format h3 {
  font-size: 15px;
}

.dropbtn {
  background-color: white;
  color: black;
  padding: 0px 26px;
  font-size: 16px;
  border-radius: 40px;
  border: none;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* .dropdown .dropbtn:hover .dropdown-content:hover {
  color: white;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
} */
