.second-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}

.seperator {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.submit-form {
  text-align: center;
  margin-top: 30px;
}

.eye-container {
  position: relative;
}

.randompas-add-users {
  position: absolute;
  top: 10px;
  cursor: pointer;
  height: 18px;
  width: 16px;
  right: 40px;
}

.eye-add-users {
  position: absolute;
  top: 10px;
  cursor: pointer;
  height: 20px;
  right: 12px;
}

.password-field {
  padding-right: 70px !important;
}

/* .second-wrapper img {
  height: 150px;
  width: 150px;
  border-radius: 100px;
} */

.submit-btn {
  padding: 10px 60px;
  border-radius: 50px;
  border: none;
  font-size: 12px;
}

.submit-btnblue {
  padding: 10px 35px;
  color: white;
}
.form-rounded {
  border-radius: 12px;
}

.error-display {
  text-align: center;
  justify-content: center;
}

.bigwrapper {
  border-radius: 50px;
  background: white;
  background-image: url("../../images/curve.svg");
  background-size: 2000px 800px;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  background-position: center -490px;
}

.curve {
  position: relative;
  height: 40px;
}

@media (max-width: 767px) {
  .seperator {
    flex-direction: column;
    gap: 0px;
  }
}
