.current-dgc img {
  height: 20px;
  width: 20px;
}

.current-dgc a {
  text-decoration: none;
  font-size: 12px;
  border-radius: 13px;
  padding: 10px 20px;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  color: white;
}

.current-dgc {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.allot-additem {
  margin-left: 32px;
}
