* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

body {
  min-height: 100vh;
}

.navigation {
  position: fixed;
  height: 100%;
  width: 250px;
  background: rgb(255, 255, 255);
  transition: 0.5s;
  overflow: hidden;
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 0;
}

.navigation ul li {
  position: relative;
  list-style: none;
  width: 100%;
}

.navigation ul li a {
  position: relative;
  display: flex;
  width: 100%;
  text-decoration: none;
  color: rgb(167, 167, 167);
  padding-left: 10px;
}

.active {
  color: black;
  box-shadow: 0px 0px 8px 1px grey;
  border-left: 4px solid rgb(2, 197, 223);
}
.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
}

.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 13px 10px 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}

.signout {
  width: 100%;

  vertical-align: middle;
}
.signout a {
  bottom: 0;
  border-radius: 15px;
  position: absolute;
  width: 80%;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  text-decoration: none;
  text-align: center;
  padding: 13px 30px;
  margin-left: 1.6em;
  color: white;
  margin-bottom: 10px;
  font-family: "GilroyRegular";
  font-size: 14px;
}

.signout a:hover {
  box-shadow: 0px 0px 5px 0.3px rgb(2, 108, 223);
  cursor: pointer;
}

.signout img {
  height: 18px;
  transform: scaleX(-1);
  margin-bottom: 0.2px;
}
.login {
  position: absolute;
  top: 4%;
  right: 3%;
  border-radius: 50px;
  min-width: 100px;
  padding: 15px 10px 15px 10px;
  text-decoration: none;
  text-align: center;
  background-color: white;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.login svg {
  height: 15px;
  margin-bottom: 5px;
}

li {
  font-size: 12px;
}

.logo {
  position: relative;
  display: block;
  padding: 17px 22px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
  margin-top: 13px;
}

.login img {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-top: -10px;
}

.login p {
  font-weight: bold;
  color: rgb(5, 75, 180);
}

.signout-text {
  color: white;
}

.wrapper .main_content {
  margin-top: 100px;
  padding-right: 3%;
  margin-left: 280px;
}

@media (max-width: 767px) {
  .wrapper .main_content {
    margin-left: 100px;
  }
}

.wrapper {
  display: flow-root;
}

@media (max-width: 767px) {
  .navigation {
    position: fixed;
    width: 76px;
  }
  .signout-text {
    display: none;
  }
  .signout a {
    margin-left: -1.6em;
  }
}
