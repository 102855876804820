.profile-img {
  height: 80px;
  width: 80px;
  border-radius: 22px;
  flex-grow: 1;
  align-self: center;
}

.custom-modal {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  padding: 22px;
  width: 280px;
  background-color: white;
  border-radius: 38px;
  border: none;
  display: flex;
  flex-direction: column;

  gap: 10px;
}
