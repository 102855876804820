.donut-1 {
  width: 100px;
  height: 100px;
  float: left;
  position: relative;
}

.donut-2 {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -20px;
  line-height: 19px;
  text-align: center;
  z-index: 999999999999999;
  font-size: 12px;
}

.donut-main {
  width: 180px;
  height: 180px;
  float: left;
  position: relative;
}

.donut-main h1 {
  font-size: 20px;
  font-weight: bold;
}

.donut-main h4 {
  font-size: 12px;
  color: rgb(56, 56, 56);
  font-weight: bold;
}

.donut-1 h2 {
  font-size: 16px;
  font-weight: bold;
  color: rgb(255, 1, 1);
}

.donut-1 h4 {
  font-size: 10px;
  color: rgb(56, 56, 56);
  font-weight: bold;
}

.donut-3 {
  width: 100px;
  height: 100px;
  float: left;
  position: relative;
}

.donut-3 h2 {
  font-size: 16px;
  font-weight: bold;
  color: rgb(11, 222, 95);
}

.donut-3 h4 {
  font-size: 10px;
  color: rgb(56, 56, 56);
  font-weight: bold;
}
