/* #45B579 */
.edititem-btn-save {
  background: #45b579;
  color: white;
}

.edititem-btn-discard {
  background: #d43b3b;
  color: white;
}
