.list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
  padding-left: 0;
}

.list a {
  text-decoration: none;
  color: black;
}

ul {
  list-style: none;
  text-align: center;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.list li a {
  display: flex;

  border-radius: 50px;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 150px;
  background: white;
}

.active-users {
  color: white;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
}
.user-array {
  display: flex;
  flex-direction: row;
  width: 900px;
  flex-wrap: wrap;
  gap: 20px;
}
