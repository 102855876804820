.editdish-btn-save {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  color: white;
}

.form-rounded-editdish {
  border-radius: 12px;
  width: 400px;
}

.editdish-btn-discard {
  background: #d43b3b;
  color: white;
}
