.redeem-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 19px;
  gap: 15px;
  background: white;
  border-radius: 30px;
  width: 400px;
}

.redeem-wrapper img {
  height: 90px;
  width: 90px;
}

.redeem-img-wrapper {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  border-radius: 24px;

  display: flex;
  align-items: center;
  padding: 14px 14px;
}

.redeem-buttons a {
  text-decoration: none;
  color: white;
  border-radius: 40px;
  font-size: 12px;
}

.redeem-redbtn {
  padding: 10px 20px;
  background: #d43b3b;
}

.redeem-bluebtn {
  padding: 10px 30px;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
}
