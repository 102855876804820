.card-content img {
  height: 80px;
  width: 80px;
  border-radius: 25px;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.edit-profile {
  align-self: flex-end;
  text-decoration: none;
  cursor: pointer;
}

.padding {
  padding: 22px;
  display: flex;
  flex-direction: column;
  width: 390px;
  gap: 510x;
}

.user-card {
  background: white;
  width: 400px;

  border-radius: 50px;
}

.dot-red {
  height: 10px;
  width: 10px;
  background-color: rgb(255, 0, 0);
  box-shadow: 0px 0px 4px 0.2px rgb(255, 0, 0);
  border-radius: 50%;
  display: inline-block;
}

.dot-green {
  height: 10px;
  width: 10px;
  background-color: rgb(0, 255, 13);
  box-shadow: 0px 0px 4px 0.2px rgb(0, 255, 13);
  border-radius: 50%;
  display: inline-block;
}

.dot-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  text-align: right;
}
