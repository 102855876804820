.em-day-wrapper {
  display: flex;
  flex-direction: row;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  width: 360px;
  padding: 25px 8px;
  border-radius: 32px;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.em-day-wrapper img {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  padding: 6px;
}

.em-day-wrapper h2 {
  font-size: 30px;
  text-align: center;
}

.em-day-profile {
  background: white;
  height: 80px;
  width: 80px;
  border-radius: 45px;
}

.em-day-wrapper h3 {
  font-size: 16px;
  color: white;
}

.em-day-wrapper h2 {
  color: white;
}

.em-day-date {
  display: inline-block;
}

.bold-text {
  font-size: 200px;
}

.em-day-wrapper h1 {
  color: white;
  text-align: center;
}
