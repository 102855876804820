.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
  border-radius: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  background: #ccc;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  border-radius: 13px;

  background: white;
  transition: 0.3s;
}

input:checked + .slider {
  transition: 0.3s;
  background: #42df61;
}

input:checked + .slider:before {
  transform: translateX(36px);
}
