.notifications-wrapper {
  background: white;
  border-radius: 32px;
  padding: 10px 24px;
  width: auto;
}

.notifications-wrapper h3 {
  font-weight: bold;
  font-size: 14px;
}

.notifications-wrapper p {
  margin-top: -4px;
  font-size: 10px;
}
