.requests-wrapper {
  display: flex;
  flex-direction: column;
  width: 345px;
  padding: 20px;
  background: white;
  border-radius: 38px;
}

.dishname {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.requests-buttons a {
  text-decoration: none;
  padding: 8px 30px;
  color: white;
  border-radius: 20px;
}

.requests-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  font-size: 12px;
}

.requests-blue {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
}

.requests-red {
  background: #d43b3b;
}
