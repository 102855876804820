.datebar-month {
  color: #124c70;
}

.form-format-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  padding-right: 40px;
}

.form-format h3 {
  font-size: 15px;
}

.dropbtn {
  background-color: white;
  color: black;
  padding: 0px 26px;
  font-size: 16px;
  border-radius: 40px;
  border: none;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.datebar-filters {
  display: flex;
  flex-direction: row;
}
