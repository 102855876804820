.ps-text {
  text-align: center;
}

.error-hndl p {
  color: red;
}

.sign-in-wrapper-ps {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20vh;
  overflow: hidden;
  gap: 3em;
  padding: 20px;
}
.sign-inbtn-ps {
  padding: 12px 70px;
  border-radius: 30px;
  border: none;
  text-align: center;
  text-decoration: none;
  color: white;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  transition: 0.5s;
}

.sign-inbtn-ps:hover {
  color: white;
  box-shadow: 0 3px 12px rgb(2, 108, 223, 0.6);
}

.sign-in-background-ps {
  overflow: hidden;
  background: #f8f8f8;
  height: 100%;
  width: 100%;
}
