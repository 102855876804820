.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: "GilroyRegular";
}

body {
  background: rgb(247, 247, 247);
  font-family: "GilroyRegular";
}

h1 {
  font-family: "GilroyRegular";
}

h3 {
  font-size: 12px;
  font-weight: lighter;
  margin: revert !important;
  padding: revert !important;
  font-family: "GilroyBlack";
}

h2 {
  font-size: 20px;
  font-weight: bold;
  font-family: "GilroyRegular";
}

p {
  font-size: 12px;
  font-weight: lighter;
  font-family: "GilroyRegular";
}

* {
  font-family: "GilroyRegular";
}

.font-face-gilroy {
  font-family: "GilroyBlack";
}

a {
  font-family: "GilroyBlack";
}

.hidden {
  display: none;
}

.blue {
  color: #34b0dd;
}

.red {
  color: #ff5e5e;
}

.green {
  color: #198631;
}

.nodata {
  margin: 0;
  position: absolute;
  top: 54%;
  left: 55%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  /* display: flex;
  flex-direction: column;
  margin: 20% auto !important;
  align-self: center;
  align-items: center;
  color: #575757; */
}

.nodata img {
  height: 60px;
}

.loader {
  margin: 0;
  position: absolute;
  top: 54%;
  left: 55%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  /* display: flex;
  margin: 30vh auto;
  justify-content: center;
  align-self: center;
  align-items: center; */
}

.wrapper-array {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;

  padding-bottom: 20px;
  gap: 20px;
}

.gear {
  /* animation: rotation 2s infinite linear; */
  display: block;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  width: 50%;
  height: 50px;
  margin-top: 26vh;
}

.display-container {
  position: relative;
}

.search-container {
  display: flex;
  flex-direction: row;
}

.user-filter {
  flex-grow: 1;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  background: white;
  margin-left: 60px;
  font-size: 15px;
}

.pagination-item {
  display: flex;
  align-items: center;
  margin-top: 80px;
  z-index: 0;
  justify-content: center;
}

.page-link {
  color: #34b0dd;
}

.page-item.active-link .page-link {
  color: white;
  background: #34b0dd;
  border-color: #34b0dd;
}

.page-link:hover {
  color: #34b0dd;
}

.none {
  display: none;
}

.red-hover:hover {
  box-shadow: 0px 0px 5px 0.3px #ff5e5e;
  cursor: pointer;
}

.green-hover:hover {
  box-shadow: 0px 0px 5px 0.3px #47bc61;
  cursor: pointer;
}
.white-hover:hover {
  box-shadow: 0px 0px 5px 0.3px #ffffff;
  cursor: pointer;
}

.blue-hover:hover {
  box-shadow: 0px 0px 5px 0.3px #34b0dd;
  cursor: pointer;
}

.black-hover:hover {
  box-shadow: 0px 0px 5px 0.3px #8f8f8f;
  cursor: pointer;
}

@media (max-width: 767px) {
  .search-container {
    display: flex;
    flex-direction: column;
  }

  .user-filter {
    margin-left: 0px;
    margin-top: 20px;
    width: 30vw;
  }
}

select {
  font-size: 12px;
}
