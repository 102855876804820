.dash-proceed {
  height: 35px;
  width: 75px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  border-radius: 7px;
}

.dash-proceed img {
  height: 20px;
  width: 20px;
  margin: auto;
}

.dash-leave-requests {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 500px;
  gap: 10px;
}

.dash-prof {
  height: 60px;
  border-radius: 20px;
}

.dash-leave-wrapper {
  width: 270px;
  background: white;
  height: 90px;
  display: flex;
  padding: 0 13px;
  border-radius: 18px;
}

.dash-leave-wrapper h2 {
  font-size: 12px;
}

.dash-leave-wrapper p {
  font-size: 10px;
  margin-top: -6px;
}
