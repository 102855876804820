.notification-panel-wrapper {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;

  border-radius: 28px;
  gap: 20px;
  transition: 0.4s;
  height: 82vh;

  right: 2em;
}

.notification-bar {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: scroll;
  position: sticky;
  background: transparent;
}
.notification-bar::-webkit-scrollbar {
  display: none;
}
.notification-panel-wrapper h2 {
  color: white;
  font-size: 24px;
}

.notification-panel-wrapper a {
  position: sticky;
  text-decoration: none;
  color: black;
  padding: 10px 0px;
  width: 100%;
  text-align: center;
  background: white;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 700;
}
