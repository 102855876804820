.proceed-del img {
  height: 40px;
  margin: auto;
}
.proceed-del {
  height: 55px;
  width: 60px;
  display: flex;
  align-items: center;
  background: #d43b3b;
  border-radius: 17px;
}

.proceed-del:hover {
  box-shadow: 0px 0px 5px 0.3px #d43b3b;
  cursor: pointer;
}

.proceed-del img {
  height: 28px;
}
