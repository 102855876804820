.cross {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  background: rgba(212, 60, 59, 255);
  border-radius: 12px;
  cursor: pointer;
}

.cross img {
  height: 15px;
  margin: auto;
}

.tick {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  background: rgba(69, 181, 122, 255);
  border-radius: 12px;
  cursor: pointer;
}

.tick img {
  height: 22px;
  margin: auto;
}

.leave-requests {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  gap: 10px;
}

.leave-desc {
  cursor: pointer;
}

.prof {
  height: 100px;
  border-radius: 20px;
}

.leave-wrapper {
  width: 500px;
  background: white;
  height: 150px;
  display: flex;
  padding: 0 13px;
  border-radius: 30px;
}

.leave-reason {
  overflow: hidden;
  height: 20px;
  text-overflow: ellipsis;
}

.check-boxes {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
