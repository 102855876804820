.allotment-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: white;
  width: 420px;
  border-radius: 30px;
}

.allotment-switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.allotment-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  text-align: center;
}

.allotment-buttons a {
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  padding: 10px 14px;

  border-radius: 30px;
}

.allotment-text {
  width: 280px;
}

.allotment-coin {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  color: white;
}

.allotment-coin img {
  height: 22px;
}

.allotment-borderbtn {
  border: 1px solid rgb(163, 163, 163);
  color: black;
}

.allotment-coindiv {
  margin-left: 75px;
}
