.del-users-modal-content {
  padding: 22px;
  width: 320px;
  position: relative;

  background-color: white;
  border-radius: 38px;
  border: none;
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.del-modal-btns a {
  font-size: 12px;
}

.del-users-modal-text {
  text-align: center;
}

.del-users-modal-text h2 {
  font-size: 16px;
  font-weight: bold;
}

.del-modal-btns {
  display: flex;
  flex-direction: row;

  gap: 10px;
  justify-content: center;
}

.del-modal-btns a {
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
  color: white;
  font-size: 12px;
  border-radius: 15px;
}

.hidden {
  display: none;
}

.custom-modal .delete-btn {
  background: #ff5e5e;
}

.custom-modal .accept-btn {
  background: #b4b4b4;
}

.custom-modal .cross {
  height: 35px;
  position: absolute;
  right: 16px;
  top: 10px;
  cursor: pointer;
}
