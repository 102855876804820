.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 28vh;
  overflow: hidden;
  gap: 3.6em;
}

.eye-container {
  position: relative;
}

.eye {
  position: absolute;
  top: 15px;
  cursor: pointer;
  height: 20px;
  right: 20px;
}

.password-field {
  padding-right: 50px !important;
}

.sign-in-background {
  position: relative;
  overflow: hidden;
  background: #f8f8f8;
}

.sign-forms {
  display: flex;
  flex-direction: column;
  font-family: "GilroyRegular";
  align-content: center;
}

.signform {
  border-radius: 30px;
  background: #f3f3f3;
  font-size: 12px;
  width: 300px;
  padding: 14px 20px;
  border: none;
  font-family: "GilroyRegular";
}

.signlogo {
  height: 70px;
}

.sign-inbtn {
  padding: 12px 70px;
  border-radius: 30px;
  text-align: center;
  border: none;
  align-self: center;
  font-size: 12px;
  text-decoration: none;
  color: white;
  margin-left: 20%;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  transition: 0.5s;
}

.sign-inbtn:hover {
  box-shadow: 0 3px 12px rgb(2, 108, 223, 0.6);
}
.sign-in-wrapper a {
  text-decoration: none;
  font-family: "GilroyRegular";
}

/* .error-msg {
  position: absolute;
  left: 320px;
  top: 20px;
  white-space: nowrap;
} */

.error-hndl {
  position: relative;
  text-align: center;
}
