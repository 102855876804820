.add-itembar-wrapper h1 {
  color: #124c70;
}

.add-itembar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-itembar-wrapper a {
  text-decoration: none;
  color: black;
  padding: 12px 35px;
  background: white;
  border-radius: 30px;
  font-size: 12px;
}
