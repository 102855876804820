.view-profile-formcol {
  display: flex;
  flex-direction: column;
}

.view-profile-form img {
  height: 200px;
  border-radius: 100px;
}

.view-profile-form {
  padding: 30px;
  min-height: 80vh;
  background: white;
  display: flex;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vendor-form-arrange {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.add-vendor-btn {
  margin-top: 30px;
  text-decoration: none;
  background: #46b85f;
  text-align: center;
  border: none;
  color: white;
  flex-grow: 1;

  padding: 15px 30px;
  border-radius: 15px;
}

.discard-vendor-btn {
  margin-top: 30px;
  text-decoration: none;
  background: rgb(238, 238, 238);
  text-align: center;
  border: none;
  color: #46b85f;

  flex-grow: 1;
  padding: 15px 30px;

  border-radius: 15px;
}

.btn-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.error-display {
  background: rgb(218, 90, 90);
  display: flex;
  max-width: 350px;
  margin-top: 50px;
  border-radius: 30px;
  padding: 20px 50px;
  color: white;
  flex-direction: column;
}

.error-messages {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .btn-container {
    flex-direction: column;
  }

  .vendor-form-arrange {
    flex-direction: column;
  }
}
