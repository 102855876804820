.dashboard-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;

  padding: 10px;
  width: 100%;
}

.dashboard-row1 {
  display: flex;
  flex-direction: row;

  flex-grow: 1;
  justify-content: flex-start;
}

.dashboard-leavereq {
  grid-column-start: 1;
  grid-column-end: 3;
}

.dashboard-content-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.dashboard-wrapper h1 {
  font-size: 29px;
  color: #124c70;
  margin-bottom: 25px;
}

.dashboard-chart-control {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dashboard-chart-control2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 1288px) {
  .dashboard-row1 {
    flex-direction: column;
    gap: 50px;
  }
  .dashboard-leavereq {
    margin-top: 50px;
  }
}

.dash-leave-array {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 17px;
}

.dash-panel {
  position: sticky;
}
