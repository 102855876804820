.attend-container {
  display: flex;
  flex-direction: row;
  gap: 10px;

  justify-content: flex-start;
  align-items: center;
}

.attend-time {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.attend-wrapper {
  background: white;
  padding: 15px;
  width: 300px;
  border-radius: 30px;
}

.attend-text {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  flex-direction: column;
}

.attend-text h3 {
  margin: 0 !important;
  padding: 0 !important;
}
.attend-text h2 {
  margin: 0;
  padding: 0;
  font-size: 15px;
}

.attend-text p {
  margin: 0;
  padding: 0;
}

.attend-container img {
  height: 70px;
  width: 70px;
  border-radius: 20px;
}

.attend-green {
  color: #00c72b;
}

.attend-red {
  color: #d10707;
}
