.proceed {
  height: 55px;
  width: 60px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  border-radius: 7px;
}

.proceed img {
  height: 40px;
  margin: auto;
}

.forgot-requests {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  gap: 10px;
}

.prof {
  height: 100px;
  border-radius: 20px;
}

.forgot-wrapper {
  width: 450px;
  background: white;
  height: 150px;
  display: flex;
  padding: 0 13px;
  border-radius: 30px;
}
