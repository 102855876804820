.form-rounded-additem {
  border-radius: 12px;
  width: 100px;
}

.redeem-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}
