.dishflex {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 25px;
  gap: 10px;
}

.dishflex img {
  height: 90px;
}

.karahi-text h2 {
  font-size: 29px;
}

/* .karahi-img {
  display: flex;
  align-self: center;
} */

.karahi {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  padding: 20px 8px;
  border-radius: 32px;
}

.karahi-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
}

.wrapper-dish {
  width: 420px;
  border-radius: 50px;
  background: white;
}

.karahi-buttons a {
  text-decoration: none;
  border-radius: 30px;
}

.karahi-delete {
  background: #d43b3b;
  border-radius: 30px;
  height: 40px;
  width: 120px;
  display: table-cell;
  vertical-align: middle;
  color: white;
}
.karahi-edit {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  border-radius: 30px;
  display: table-cell;
  vertical-align: middle;
  height: 40px;
  width: 120px;
  color: white;
}

.karahi-text {
  text-align: center;
}
