.form-rounded-search {
  border-radius: 30px;
  border: none;
  width: 90%;
  flex-grow: 1;
  font-family: "Poppins", sans-serif;
  height: 50px;
  flex-shrink: 0;
  background: rgb(255, 255, 255);
}

.form-filter {
  border-radius: 30px;
  border: none;

  font-family: "GilroyRegular";
}

.search-btn {
  background: none;
  height: 100%;
}

.search-btn img {
  height: 20px;
}

.form-format {
  display: flex;
  flex-direction: row;
  flex-wrap: none;
  justify-content: flex-start;
}

.form-format h3 {
  font-size: 15px;
}

.dropbtn {
  background-color: white;
  color: black;
  padding: 0px 26px;
  font-size: 16px;
  border-radius: 40px;
  border: none;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* .dropdown .dropbtn:hover .dropdown-content:hover {
      color: white;
      background: linear-gradient(
        132deg,
        rgb(2, 108, 223) 0%,
        rgb(89, 217, 222) 90.49%
      );
    } */

.search-with-icon {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 767px) {
  .form-format {
    flex-direction: column;
  }

  .search-with-icon {
    width: 90vw;
  }
}
