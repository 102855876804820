.dgc-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 10px;
  background: white;
  width: 400px;
  border-radius: 30px;
}

.dgc-wrapper img {
  height: 93px;
  width: 93px;
  border-radius: 30px;
}

.dgc-buttons {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 10px;
  align-items: center;
}
.dgc-buttons a {
  text-decoration: none;
  font-size: 12px;
  text-align: center;
}

.dgc-buttons img {
  height: 20px;
  width: 20px;
  align-items: center;
}

.coin-compromise {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

.dgc-coin {
  background: linear-gradient(
    132deg,
    rgb(2, 108, 223) 0%,
    rgb(89, 217, 222) 90.49%
  );
  color: white;
  padding: 8px 17px;
  border-radius: 40px;
}

.dgc-allot {
  background: white;
  color: black;
  padding: 10px 25px;
  border-radius: 40px;
}

.dgc-wrapper h2 {
  font-size: 24px;
}
