.profile-img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.modal-row1 {
  align-items: center;
}

.leave-req-modal {
  max-height: 300px;
  overflow: auto;
}

.custom-modal {
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.219);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  padding: 22px;
  width: 280px;

  background-color: white;
  border-radius: 38px;
  border: none;
}

.close-btn {
  height: 20px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
